/** **************************** Import Types ****************************** */
import { DRILLS_SUBTOPICS_REQUEST, DRILLS_SUBTOPICS_SUCCESS,  DRILLS_SUBTOPICS_FAILURE } from "../types/drillsSubtopics.types";

const initialState = {
  storeItemResponse: "",
  error: "",
  loading: false,
};

const DrillsSubtopicsReducer = (state = initialState, action) => {

  switch (action.type) {
    case DRILLS_SUBTOPICS_REQUEST:
      return {
        ...state,
        storeItemResponse: "",
        loading: true,
      };
    case DRILLS_SUBTOPICS_SUCCESS:
      return {
        ...state,
        storeItemResponse: action.payload,
        loading: false,
      };
    case  DRILLS_SUBTOPICS_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};

export default DrillsSubtopicsReducer;
