/** **************************** Import Types ****************************** */
import {
    REPORTLISTING_REQUEST,
    REPORTLISTING_SUCCESS,
    REPORTLISTING_FAILURE,
  } from "../types/openReports.types";
  
  const initialState = {
    storeItemResponse: "",
    error: "",
    loading: false,
  };
  
  const OpenReportsReducer = (state = initialState, action) => {
    switch (action.type) {
      case REPORTLISTING_REQUEST:
        return {
          ...state,
  
          loading: true,
        };
      case REPORTLISTING_SUCCESS:
        return {
          ...state,
          storeItemResponse: action.payload,
          loading: false,
        };
      case REPORTLISTING_FAILURE:
        return {
          ...state,
          error: action.payload,
          loading: false,
        };
      default:
        return state;
    }
  };
  
  export default OpenReportsReducer;
  