export const camelize = (str) => {
  const result = str
    .replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) =>
      index === 0 ? word.toLowerCase() : word.toUpperCase()
    )
    .replace(/\s+/g, " ");
  return result;
};

export const normalize = (str) => {
  if (typeof string == "string") {
    const result = str?.replace(/([A-Z])/g, " $1");
    const finalResult = result?.charAt(0).toUpperCase() + result?.slice(1);
    return finalResult;
  } else {
    return str;
  }
};
export const capitalizeWords = (str) => {
  if (str) {
    return str?.replace(/\b\w/g, (match) => match.toUpperCase());
  }
};
export const capitalizeFirstLetter = (string) => {
  return typeof string == "string"
    ? string?.charAt(0)?.toUpperCase() +
        `${string}`
          ?.slice(1)
          .replace(
            /([.]\s*)([a-z])/g,
            (match, prefix, letter) => `${prefix}${letter.toUpperCase()}`
          )
    : string;
};
