/** **************************** Import Types ****************************** */
import {
  ADMINUSER_REQUEST,
  ADMINUSER_SUCCESS,
  ADMINUSER_FAILURE,
} from "../types/adminUser.types";

const initialState = {
  storeItemResponse: "",
  error: "",
  loading: false,
};

const AdminUserReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADMINUSER_REQUEST:
      return {
        ...state,

        loading: true,
      };
    case ADMINUSER_SUCCESS:
      return {
        ...state,
        storeItemResponse: action.payload,
        loading: false,
      };
    case ADMINUSER_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};

export default AdminUserReducer;
