/** **************************** Import Types ****************************** */
import {
  BOOTCAMP_QUESTIONS_REQUEST,
  BOOTCAMP_QUESTIONS_SUCCESS,
  BOOTCAMP_QUESTIONS_FAILURE,
} from "../types/bootcampQuestions.types";

const initialState = {
  storeItemResponse: "",
  error: "",
  loading: false,
};

const BootcampQuestionsReducer = (state = initialState, action) => {
 
  switch (action.type) {
    case BOOTCAMP_QUESTIONS_REQUEST:
      return {
        ...state,
        storeItemResponse:action.payload || "",
        loading: true,
      };
    case BOOTCAMP_QUESTIONS_SUCCESS:
      return {
        ...state,
        storeItemResponse: action.payload,
        loading: false,
      };
    case BOOTCAMP_QUESTIONS_FAILURE:
      return {
        ...state,
        storeItemResponse:action.payload ,
        error: action.error,
        loading: false,
      };
    default:
      return state;
  }
};

export default BootcampQuestionsReducer;
