/** **************************** Import Types ****************************** */
import {
    SUBSCRIPTION_REQUEST,
    SUBSCRIPTION_SUCCESS,
    SUBSCRIPTION_FAILURE,
  } from "../types/subscription.types";
  
  const initialState = {
    storeItemResponse: "",
    error: "",
    loading: false,
  };
  
  const SubscriptionReducer = (state = initialState, action) => {
    switch (action.type) {
      case SUBSCRIPTION_REQUEST:
        return {
          ...state,
          storeItemResponse: "",
          loading: true,
        };
      case SUBSCRIPTION_SUCCESS:
        return {
          ...state,
          storeItemResponse: action.payload,
          loading: false,
        };
      case SUBSCRIPTION_FAILURE:
        return {
          ...state,
          error: action.payload,
          loading: false,
        };
      default:
        return state;
    }
  };
  
  export default SubscriptionReducer;
  