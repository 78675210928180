

/** **************************** Import Libs ****************************** */
import { createStore, applyMiddleware } from "redux";
import logger from "redux-logger";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";

/** **************************** Import Root Reducer ****************************** */
import { environment } from "../config";
import rootReducer from "./rootReducer";

let middleware = [thunk];

// Add logger middleware in development environment
if (environment === "development") {
  middleware = [...middleware, logger];
}

const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;

