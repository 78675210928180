/** **************************** Import Types ****************************** */
import {
  SUBTOPICS_REQUEST,
  SUBTOPICS_SUCCESS,
  SUBTOPICS_FAILURE,
} from "../types/subtopics.types";

const initialState = {
  storeItemResponse: "",
  error: "",
  loading: false,
};

const SubtopicsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SUBTOPICS_REQUEST:
      return {
        ...state,
        storeItemResponse: "",
         loading: true,
      };
    case SUBTOPICS_SUCCESS:
      return {
        ...state,
        storeItemResponse: action.payload,
        loading: false,
      };
    case SUBTOPICS_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};

export default SubtopicsReducer;
