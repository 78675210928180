/** **************************** Import Types ****************************** */
import {
    NOTIFICATION_REQUEST,
    NOTIFICATION_SUCCESS,
    NOTIFICATION_FAILURE,
   } from "../types/notification.types";
   
   const initialState = {
     storeItemResponse: "",
     error: "",
     loading: false,
   };
   
   const NotificationReducer = (state = initialState, action) => {
     switch (action.type) {
       case NOTIFICATION_REQUEST:
         return {
           ...state,
   
           loading: true,
         };
       case NOTIFICATION_SUCCESS:
         return {
           ...state,
           storeItemResponse: action.payload,
           loading: false,
         };
       case NOTIFICATION_FAILURE:
         return {
           ...state,
           error: action.payload,
           loading: false,
         };
       default:
         return state;
     }
   };
   
   export default NotificationReducer;
   