/** **************************** Import Types ****************************** */
import {
  BOOTCAMP_SUBTOPICS_REQUEST,
  BOOTCAMP_SUBTOPICS_SUCCESS,
  BOOTCAMP_SUBTOPICS_FAILURE,
} from "../types/bootcampSubtopics.types";

const initialState = {
  storeItemResponse: "",
  error: "",
  loading: false,
};

const BootcampSubtopicsReducer = (state = initialState, action) => {
  switch (action.type) {
    case BOOTCAMP_SUBTOPICS_REQUEST:
      return {
        ...state,
        storeItemResponse:"",
        loading: true,
      };
    case BOOTCAMP_SUBTOPICS_SUCCESS:
      return {
        ...state,
        storeItemResponse: action.payload,
        loading: false,
      };
    case BOOTCAMP_SUBTOPICS_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};

export default BootcampSubtopicsReducer;
