import { BREADCRUMB } from "../types/breadcrumb.types";

const initialState = {
    storeItemResponse: "",
    error: "",
    loading: false,
};

const BreadcrumbReducer = (state = initialState, action) => {
    switch (action.type) {
        case BREADCRUMB:
            return {
                ...state,
                storeItemResponse: action.payload,
                loading: false,
            };
        default:
            return state;
    }
};

export default BreadcrumbReducer;