/** **************************** Import Types ****************************** */
import {
    FAQ_REQUEST,
    FAQ_SUCCESS,
    FAQ_FAILURE,
  } from "../types/faq.types";
  
  const initialState = {
    storeItemResponse: "",
    error: "",
    loading: false,
  };
  
  const FaqReducer = (state = initialState, action) => {
    switch (action.type) {
      case FAQ_REQUEST:
        return {
          ...state,
          storeItemResponse: "",
          loading: true,
        };
      case FAQ_SUCCESS:
        return {
          ...state,
          storeItemResponse: action.payload,
          loading: false,
        };
      case FAQ_FAILURE:
        return {
          ...state,
          error: action.payload,
          loading: false,
        };
      default:
        return state;
    }
  };
  
  export default FaqReducer;
  