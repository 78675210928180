/* ************************** Import Packages *************************** **/
import React from "react";

/* ************************** Import Components *************************** **/
import { Button } from "./style";
import AddIcon from "../../../assets/images/addIcon.svg";
import { capitalizeFirstLetter } from "../../../utils/camelizeAndNormalize";
const ButtonComponent = (props) => {
  const {
    text,
    textTransform,
    onClick,
    width,
    height,
    background,
    fontSize,
    border,
    borderRadius,
    color,
    margin,
    display,
    disabled,
    fontWeight,
    selectedValues,
    marginRight,
    type,
    Icon,
    ...others
  } = props;

  return (
    <Button
      type={type}
      width={width}
      height={height}
      fontSize={fontSize}
      background={background}
      border={border}
      borderRadius={borderRadius}
      color={color}
      onClick={onClick}
      margin={margin}
      display={display}
      fontWeight={fontWeight}
      disabled={disabled}
      textTransform={textTransform}
      marginRight={marginRight}
      {...others}
    >
      {Icon ? <img src={AddIcon} alt="add-icon" width="18px" /> : ""} {capitalizeFirstLetter(text)}{" "}
      {selectedValues ? <span>{selectedValues}</span> : null}
    </Button>
  );
};

export default ButtonComponent;
