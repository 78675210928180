/** ************************* Import config files *************************** */
import { hostConfig } from "../config/index";

/** ************************* Import config files *************************** */

/** *************************** Response Handler **************************** */
const responseStatusHandler = (response) => {
  switch (response.status) {
    case 400:
      return response;
    case 401:
      return { error: "Unauthorized" };
    case 402:
      return { error: "Payment Required" };
    case 403:
      setTimeout(() => {
        localStorage.clear();
        window.location.href = "/";
      }, 100);
      break;
    case 404:
      return { error: "Not Found" };
    case 405:
      return { error: "Method Not Allowed" };
    case 406:
      return { error: "Not Acceptable" };
    case 408:
      return { error: "Request Timeout" };
    case 409:
      return { error: "User Already Exist" };
    case 410:
      return { error: "permanently deleted from server" };
    case 413:
      return { error: "Request Entity Too Large" };
    case 422:
      return { error: "Unprocessable Entity" };
    case 500:
      return { error: "Internal Server Error" };
    case 501:
      return { error: "Not Implemented" };
    case 502:
      return { error: "Bad Gateway" };
    case 503:
      return { error: "Service Unavailable" };
    case 504:
      return { error: " Gateway Timeout" };
    case 511:
      return { error: " Network Authentication Required" };
    case 200:
      return response;
    case 201:
      return response;
    default:
      return false;
  }
};

/** ****************************** Error Handler *********************************** */
const errorHandler = (error) => error;

/** ***************************** Get Access Token ********************************* */
let token;
if (typeof window !== "undefined") {
  // This is only for pre-rendering application
  token = localStorage.getItem("accessToken");
}

/** ****************************** Create Form Data Api *********************************** */
export const PostFormDataApi = (requestUrl, params) =>
  fetch(`${hostConfig.API_URL}${requestUrl}`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
    body: params,
  })
    .then((response) => responseStatusHandler(response))
    .then((result) =>
      result.status === 200 || result.status === 201 || result.status === 400
        ? result.json()
        : result
    )
    .catch((err) => {
      errorHandler(err);
    });

// ******************************** Create MultiImage Data *******************************

// ******************************** Create MultiImage Data *******************************/

export const MultiuploadDuplicateFile = async (requestUrl, params) => {
  const headers = new Headers();
  headers.append("Authorization", `Bearer ${token}`);

  fetch(`${hostConfig.API_URL}${requestUrl}`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(params),
  })
    .then((result) => {
      if (!result.error) return result.json();
      else return result;
    })
    .catch((err) => {
      errorHandler(err);
    });
};

/** ****************************** Create Api *********************************** */

export const postDataApi = (requestUrl, params) => {
  let getParams = "?";

  if (params?.module_id) {
    getParams += `module_id=${params.module_id}`;
  }
  if (params?.plan_id) {
    getParams += params.days
      ? `plan_id=${params.plan_id}&user_id=${params.user_id}&days=${params.days}`
      : `plan_id=${params.plan_id}&user_id=${params.user_id}`;
  }

  return fetch(`${hostConfig.API_URL}${requestUrl}${getParams}`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(params),
  })
    .then((response) => responseStatusHandler(response))
    .then((result) =>
      result.status === 200 || result.status === 201 || result.status === 400
        ? result.json()
        : result
    )
    .catch((err) => {
      errorHandler(err);
    });
};
/** ****************************** Create Api *********************************** */
export const postFormDataApi = (requestUrl, params) => {
  return fetch(`${hostConfig.API_URL}${requestUrl}`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data",
      Accept: "application/json",
    },
    body: params,
  })
    .then((response) => {
      return responseStatusHandler(response);
    })
    .then((result) =>
      result.status === 200 || result.status === 201 ? result.json() : result
    )
    .catch((err) => {
      errorHandler(err);
    });
};

/** ****************************** Post Request by Id *********************************** */
export const postRequesByIdApi = (requestUrl) =>
  fetch(`${hostConfig.API_URL}${requestUrl}`, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    // body: JSON.stringify(params),
  })
    .then((response) => responseStatusHandler(response))
    .then((result) =>
      result.status === 200 || result.status === 201 || result.status === 400
        ? result.json()
        : result
    )
    .catch((err) => {
      errorHandler(err);
    });
/**********************************************ImageUpload api******************************** */

export const uploadFile = async (requestUrl, params) => {
  let getParams = "?";

  if (params?.topic_id) {
    getParams += `topic_id=${params.topic_id}`;
  }
  if (params?.module_id) {
    getParams += `module_id=${params.module_id}`;
  }
  if (params?.test_id) {
    getParams += `test_id=${params.test_id}`;
  }
  if (params?.image_id) {
    getParams += `image_id=${params.image_id}`;
  }
  if (params?.user_id) {
    getParams += `user_id=${params.user_id}`;
  }
  if (params?.id) {
    getParams += `id=${params.id}`;
  }
  const headers = new Headers();
  headers.append("Authorization", `Bearer ${token}`);

  const formData = new FormData();

  formData.append("file", params?.file[0]);

  return fetch(`${hostConfig.API_URL}${requestUrl}${getParams}`, {
    method: "POST",
    headers: headers,
    body: formData,
  })
    .then(async (response) => {
      const data = responseStatusHandler(response);
      return data;
    })
    .then((result) => {
      if (!result.error) return result.json();
      else return result;
    })
    .catch((err) => {
      errorHandler(err);
    });
};
/******************************downloadapi****************************** */
export const getZipFile = (requestUrl, params) => {
  let getParams = "?";

  if (params && params._id && params._id !== null && params._id !== undefined) {
    getParams += `&_id=${params._id}`;
  }
  if (
    params &&
    params.topic_id &&
    params.topic_id !== null &&
    params.topic_id !== undefined
  ) {
    getParams += `topic_id=${params.topic_id}`;
  }
  if (
    params &&
    params.subtopic_id &&
    params.subtopic_id !== null &&
    params.subtopic_id !== undefined
  ) {
    getParams += `&subtopic_id=${params.subtopic_id}`;
  }
  if (
    params &&
    params.module_ids &&
    params.module_ids !== null &&
    params.module_ids !== undefined
  ) {
    getParams += `module_ids=${params.module_ids}`;
  }
  if (
    params &&
    params.test_ids &&
    params.test_ids !== null &&
    params.test_ids !== undefined
  ) {
    getParams += `test_ids=${params.test_ids}`;
  }
  if (
    params &&
    params.notes_ids &&
    params.notes_ids !== null &&
    params.notes_ids !== undefined
  ) {
    getParams += `notes_ids=${params.notes_ids}`;
  }
  if (
    params &&
    params.type &&
    params.type !== null &&
    params.type !== undefined
  ) {
    getParams += `type=${params.type}`;
  }
  return fetch(`${hostConfig.API_URL}${requestUrl}${getParams}`, {
    method: "GET",
    headers: {
      Accept: "application/zip",
      "Content-Type": "application/zip",
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => responseStatusHandler(response))
    .then((result) => result)
    .catch((error) => {
      errorHandler(error);
    });
};
/** **************************** List Api by Query ********************************* */
export const getListByApi = (requestUrl, params) => {
  let getParams = "?";

  if (
    params &&
    params.rowsPerPage &&
    params.rowsPerPage !== null &&
    params.rowsPerPage !== undefined
  ) {
    getParams += `limit=${params.rowsPerPage}`;
  }

  if (
    params &&
    params.sort &&
    params.sort !== null &&
    params.sort !== undefined
  ) {
    getParams += `&sort=${params.sort}`;
  }

  if (params && params._id && params._id !== null && params._id !== undefined) {
    getParams += `&_id=${params._id}`;
  }
  if (
    params &&
    params.topic_id &&
    params.topic_id !== null &&
    params.topic_id !== undefined
  ) {
    getParams += `topic_id=${params.topic_id}`;
  }
  if (
    params &&
    params.subtopic_id &&
    params.subtopic_id !== null &&
    params.subtopic_id !== undefined
  ) {
    getParams += `&subtopic_id=${params.subtopic_id}`;
  }
  if (
    params &&
    params.module_id &&
    params.module_id !== null &&
    params.module_id !== undefined
  ) {
    getParams += `&module_id=${params.module_id}`;
  }
  if (
    params &&
    params.test_id &&
    params.test_id !== null &&
    params.test_id !== undefined
  ) {
    getParams += `test_id=${params.test_id}`;
  }
  if (
    params &&
    params.is_active !== null &&
    params.is_active !== "" &&
    params.is_active !== undefined
  ) {
    getParams += `&is_active=${params.is_active}`;
  }
  if (
    params &&
    params.status !== null &&
    params.status !== "" &&
    params.status !== undefined
  ) {
    getParams += `&status=${params.status}`;
  }
  if (
    params &&
    params.page !== null &&
    params.page !== "" &&
    params.page !== undefined
  ) {
    getParams += `&page=${params.page}`;
  }
  if (
    params &&
    params.create_on !== null &&
    params.create_on !== "" &&
    params.create_on !== undefined
  ) {
    getParams += `&create_on=${params.create_on}`;
  }
  if (
    params &&
    params.limit !== null &&
    params.limit !== "" &&
    params.limit !== undefined
  ) {
    getParams += `&limit=${params.limit}`;
  }

  if (
    params &&
    params.search !== null &&
    params.search !== "" &&
    params.search !== undefined
  ) {
    getParams += `&search=${params.search}`;
  }

  if (
    params &&
    params.sort_by_column &&
    params.sort_by_column !== null &&
    params.sort_by_column !== undefined
  ) {
    getParams += `&sort_by_column=${params.sort_by_column}`;
  }

  if (
    params &&
    params.sort_by &&
    params.sort_by !== null &&
    params.sort_by !== undefined
  ) {
    getParams += `&sort_by=${params.sort_by}`;
  }
  if (
    params &&
    params.type &&
    params.type !== null &&
    params.type !== undefined
  ) {
    getParams += `&type=${params.type}`;
  }
  if (
    params &&
    params.Type !== "" &&
    params.Type !== null &&
    params.Type !== undefined
  ) {
    getParams += `&type=${params.Type}`;
  }
  if (
    params &&
    params.course &&
    params.course !== null &&
    params.course !== undefined
  ) {
    getParams += `&course=${params.course}`;
  }
  if (
    params &&
    params.Status !== "" &&
    params.Status !== null &&
    params.Status !== undefined
  ) {
    getParams += `&status=${params.Status}`;
  }
  if (
    params &&
    params.Users !== "" &&
    params.Users !== null &&
    params.Users !== undefined
  ) {
    getParams += `&users=${params.Users}`;
  }
  if (
    params &&
    params.year &&
    params.year !== null &&
    params.year !== undefined
  ) {
    getParams += `&year=${params.year}`;
  }
  if (
    params &&
    params.user_id &&
    params.user_id !== null &&
    params.user_id !== undefined
  ) {
    getParams += `&user_id=${params.user_id}`;
  }
  if (
    params &&
    params.subject_id &&
    params.subject_id !== null &&
    params.subject_id !== undefined
  ) {
    getParams += `&subject_id=${params.subject_id}`;
  }
  if (
    params &&
    params.module_type &&
    params.module_type !== null &&
    params.module_type !== undefined
  ) {
    getParams += `&module_type=${params.module_type}`;
  }
  return fetch(`${hostConfig.API_URL}${requestUrl}${getParams}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => responseStatusHandler(response))
    .then((result) => result.json())
    .catch((error) => {
      errorHandler(error);
    });
};

/******************************** List Api By Id************************************/
export const viewDataByApi = (requestUrl, id) =>
  fetch(`${hostConfig.API_URL}${requestUrl}/${id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => responseStatusHandler(response))
    .then((result) => result.json())
    .catch((error) => {
      errorHandler(error);
    });
export const viewDataParamsByApi = (requestUrl, id, params) => {
  let getParams = "?";

  if (
    params &&
    params.type &&
    params.type !== null &&
    params.type !== undefined
  ) {
    getParams += `type=${params.type}`;
  }
  if (
    params &&
    params.rating &&
    params.rating !== null &&
    params.rating !== undefined
  ) {
    getParams += `&rating=${params.rating}`;
  }
  if (
    params &&
    params.sort_by_column &&
    params.sort_by_column !== null &&
    params.sort_by_column !== undefined
  ) {
    getParams += `&sort_by_column=${params.sort_by_column}`;
  }
  if (
    params &&
    params.sort_by &&
    params.sort_by !== null &&
    params.sort_by !== undefined
  ) {
    getParams += `&sort_by=${params.sort_by}`;
  }
  return fetch(`${hostConfig.API_URL}${requestUrl}/${id}/${getParams}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => responseStatusHandler(response))
    .then((result) => result.json())
    .catch((error) => {
      errorHandler(error);
    });
};
/** ****************************** Update Api *********************************** */
export const putDataApi = (requestUrl, params, id) =>
  fetch(`${hostConfig.API_URL}${requestUrl}/${id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(params),
  })
    .then((response) => responseStatusHandler(response))
    .then((result) =>
      result.status === 200 || result.status === 201 || result.status === 400
        ? result.json()
        : result
    )
    .catch((error) => {
      errorHandler(error);
    });

export const putImagesApi = (requestUrl, params, id) => {
  fetch(`${hostConfig.API_URL}${requestUrl}?material_id=${id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(params),
  })
    .then((response) => responseStatusHandler(response))
    .then((result) =>
      result.status === 200 || result.status === 201 || result.status === 400
        ? result.json()
        : result
    )
    .catch((error) => {
      errorHandler(error);
    });
};
/** ****************************** Delete Api *********************************** */

export const deleteDataByIdApi = (requestUrl, id) =>
  fetch(`${hostConfig.API_URL}${requestUrl}/${id}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => responseStatusHandler(response))
    .then((result) =>
      result.status === 200 || result.status === 201 || result.status === 400
        ? result.json()
        : result
    )
    .catch((error) => {
      errorHandler(error);
    });
export const deleteMultipleData = (requestUrl, params) => {
  let getParams = "?";

  if (params?.ids) {
    getParams += `ids=${params.ids}`;
  }
  if (params?.module_id) {
    getParams += `&module_id=${params.module_id}`;
  }

  if (
    params &&
    params.subtopic_ids &&
    params.subtopic_ids !== null &&
    params.subtopic_ids !== undefined
  ) {
    getParams += `&subtopic_ids=${params.subtopic_ids}`;
  }
  if (
    params &&
    params.question_ids &&
    params.question_ids !== null &&
    params.question_ids !== undefined
  ) {
    getParams += `question_ids=${params.question_ids}`;
  }
  return fetch(`${hostConfig.API_URL}${requestUrl}${getParams}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => responseStatusHandler(response))
    .then((result) =>
      result.status === 200 || result.status === 201 || result.status === 400
        ? result.json()
        : result
    )
    .catch((error) => {
      errorHandler(error);
    });
};
/** ****************************** Post the Image flag Api *********************************** */

export const postRequest = (requestUrl, id, params) => {
  return fetch(`${hostConfig.API_URL}${requestUrl}`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      user_place_id: id,
      image_file_names: params,
    }),
  })
    .then((response) => {
      return responseStatusHandler(response);
    })
    .then((result) =>
      result.status === 200 || result.status === 201 || result.status === 400
        ? result.json()
        : result
    )
    .catch((err) => {
      errorHandler(err);
    });
};

export const putData = (requestUrl, params) =>
  fetch(`${hostConfig.API_URL}${requestUrl}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(params),
  })
    .then((response) => responseStatusHandler(response))
    .then((result) =>
      result.status === 200 || result.status === 201 || result.status === 400
        ? result.json()
        : result
    )
    .catch((error) => {
      errorHandler(error);
    });
