/** **************************** Import Types ****************************** */
import {
    INQUIRY_REQUEST,
    INQUIRY_SUCCESS,
    INQUIRY_FAILURE,
  } from "../types/inquiry.types";
  
  const initialState = {
    storeItemResponse: "",
    error: "",
    loading: false,
  };
  
  const InquiryReducer = (state = initialState, action) => {
    switch (action.type) {
      case INQUIRY_REQUEST:
        return {
          ...state,
          storeItemResponse: "",
          loading: true,
        };
      case INQUIRY_SUCCESS:
        return {
          ...state,
          storeItemResponse: action.payload,
          loading: false,
        };
      case INQUIRY_FAILURE:
        return {
          ...state,
          error: action.payload,
          loading: false,
        };
      default:
        return state;
    }
  };
  
  export default InquiryReducer;
  