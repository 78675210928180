const config = {

  production: {
    webUrl: process.env.REACT_APP_WEB_URL_PRODUCTION,
    apiUrl: process.env.REACT_APP_API_URL_PRODUCTION,
    bucketName: process.env.REACT_APP_BUCKET_NAME,
    image_url: process.env.REACT_APP_API_URL_PRODUCTION_IMG_URL
  },

  staging: {
    webUrl: process.env.REACT_APP_WEB_URL_STAGING,
    apiUrl: process.env.REACT_APP_API_URL_STAGING,
    bucketName: process.env.REACT_APP_BUCKET_NAME,
    image_url: process.env.REACT_APP_API_URL_STAGING_IMG_URL
  },

  development: {
    webUrl: process.env.REACT_APP_WEB_URL_DEVELOPMENT,
    apiUrl: process.env.REACT_APP_API_URL_DEVELOPMENT,
    bucketName: process.env.REACT_APP_BUCKET_NAME,
    image_url:process.env.REACT_APP_API_URL_DEVELOPMENT_IMG_URL
  },

  localhost: {
    webUrl: process.env.REACT_APP_WEB_URL_LOCALHOST,
    apiUrl: process.env.REACT_APP_API_URL_LOCALHOST,
    bucketName: process.env.REACT_APP_BUCKET_NAME,
    image_url: process.env.REACT_APP_API_URL_LOCALHOST_IMG_URL
  },
};


export const environment = process.env.REACT_APP_MODE;

const hostConfig = {

  WEB_URL: config[environment]?.webUrl,
  IMAGE_URL: config[environment].image_url,
  FILE_URL: `https://${config[environment].bucketName}.s3.amazonaws.com`,
  API_URL: config[environment].apiUrl,
  S3_BUCKET: `${config[environment].bucketName}`,
  AWS_ACCESS_KEY_ID: process.env.REACT_APP_ACCESS_KEY_ID,
  AWS_SECRET_ACCESS_KEY: process.env.REACT_APP_SECRET_ACCESS_KEY,
  AWS_REGION: process.env.REACT_APP_AWS_REGION,
};

export { hostConfig };


