/** **************************** Import Types ****************************** */
import {
    MOBILEUSER_REQUEST,
    MOBILEUSER_SUCCESS,
    MOBILEUSER_FAILURE,
  } from "../types/mobileUser.types";
  
  const initialState = {
    storeItemResponse: "",
    error: "",
    loading: false,
  };
  
  const MobileuserReducer = (state = initialState, action) => {
    switch (action.type) {
      case MOBILEUSER_REQUEST:
        return {
          ...state,
          storeItemResponse: "",
          loading: true,
        };
      case MOBILEUSER_SUCCESS:
        return {
          ...state,
          storeItemResponse: action.payload,
          loading: false,
        };
      case MOBILEUSER_FAILURE:
        return {
          ...state,
          error: action.payload,
          loading: false,
        };
      default:
        return state;
    }
  };
  
  export default MobileuserReducer;
  