/* ************************** Import Packages *************************** **/
import React from "react";

const ButtonLoader = () => {
  return (
    <>
      <span
        className="spinner-border spinner-border-sm ml-2"
        role="status"
        aria-hidden="false"
        style={{ backgroundColor: "none" }}
      />
    </>
  );
};

export default ButtonLoader;
