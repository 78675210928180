import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const InputStyled = styled.input`
  position: relative;
  padding: 0.5rem;
  margin: ${(props) => props.margin};
  width: ${(props) => (props.width ? props.width : "100%")};
  height: ${(props) => (props.height ? props.height : "3rem")};
  border: 0.5px solid #e9e9e9;
  border-radius: 8px;
  text-transform: ${(props) =>
    props?.textTransform ? props?.textTransform : "none"};
  background-color: ${(props) =>
    props.backgroundColor ? props.backgroundColor : "white"};
  outline: none;
`;

const Form = (props) => {
  const {
    onClick,
    onChange,
    placeholder,
    name,
    value,
    type,
    onMouseEnter,
    onMouseLeave,
    onBlur,
    width,
    height,
    margin,
    onKeyDown,
    ref,
    check,
    textTransform,
    defaultValue,
    disable,
    backgroundColor,
    ...others
  } = props;
  return (
    <InputStyled
      value={value}
      name={name}
      onClick={onClick}
      onChange={onChange}
      type={type}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onBlur={onBlur}
      width={width}
      height={height}
      ref={ref}
      checked={check}
      placeholder={placeholder}
      margin={margin}
      textTransform={textTransform}
      onKeyDown={onKeyDown}
      defaultValue={defaultValue}
      disabled={disable}
      backgroundColor={backgroundColor}
      {...others}
    />
  );
};

Form.propTypes = {
  onChange: PropTypes.string,
  placeholder: PropTypes.string,
  name: PropTypes.string,
  onClick: PropTypes.string,
  onMouseEnter: PropTypes.string,
  type: PropTypes.string,
  onMouseLeave: PropTypes.string,
  onBlur: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  textTransform: PropTypes.string,
  value: PropTypes.string,
  defaultValue: PropTypes.number,
};

export default Form;
