// import required packages
import styled from "styled-components";

export const Button = styled.button`

  background-color: ${(props) =>
    props.background ? props.background : "#006AFF"};
  font-size: ${(props) => (props.fontSize ? props.fontSize : "14px")};
  color: ${(props) => (props.color ? props.color : "white")};
  width: ${(props) => (props.width ? props.width : "100px")};
  height: ${(props) => (props.height ? props.height : "44px")};
  border: ${(props) => (props.border ? props.border : "1px solid #E8ECF4")};
  border-radius: ${(props) =>
    props.borderRadius ? props.borderRadius : "8px"};
  margin: ${(props) => props.margin};
  padding: ${(props) => (props.padding ? props.padding : "8px 8px 8px 8px")};
  cursor: ${(props) => (props.disabled ? `not-allowed` : `pointer`)};
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : "600")};
  text-transform:${(props) => props.textTransform}

  border: ${(props) => (props.border ? props.border : "1px solid #E9E9E9")};
 outline:none;
 margin-right:${(props) => (props.marginRight ? props.marginRight : "10px")};
//  letter-spacing:0.5px;
 img{
  margin-top:-4px;
  margin-right:5px
 }
  span {
    border-radius: 50%;
    // color: white;
    // background-color: #f5821f;
    padding: 4px 6px;
    // margin-left:9px;
    font-size:10px;
  }
  @media (min-width :1024px) and (max-width:1300px){
    width:auto;
  min-width:110px
  }
  
`;
