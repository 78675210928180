/** **************************** Import Types ****************************** */
import { DRILLS_MODULES_REQUEST, DRILLS_MODULES_SUCCESS, DRILLS_MODULES_FAILURE } from "../types/drillsModule.types";

const initialState = {
  storeItemResponse: "",
  error: "",
  loading: false,
};

const DrillsModuleReducer = (state = initialState, action) => {
  
  switch (action.type) {
    case DRILLS_MODULES_REQUEST:
      return {
        ...state,
        storeItemResponse: "",
        loading: true,
      };
    case DRILLS_MODULES_SUCCESS:
      return {
        ...state,
        storeItemResponse: action.payload,
        loading: false,
      };
    case DRILLS_MODULES_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};

export default DrillsModuleReducer;
