/** **************************** Import Types ****************************** */
import { NOTESADD_REQUEST, NOTESADD_SUCCESS, NOTESADD_FAILURE } from "../types/notesAdd.types";

const initialState = {
  storeItemResponse: "",
  error: "",
  loading: false,
};

const NotesAddReducer = (state = initialState, action) => {

  switch (action.type) {
    case NOTESADD_REQUEST:
      return {
        ...state,
        storeItemResponse: action.payload || "",
        loading: true,
      };
    case NOTESADD_SUCCESS:
      return {
        ...state,
        storeItemResponse: action.payload,
        loading: false,
      };
    case NOTESADD_FAILURE:
      return {
        ...state,
        storeItemResponse:action.payload,
        error: action.error,
        loading: false,
      };
    default:
      return state;
  }
};

export default NotesAddReducer;
