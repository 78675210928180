/** **************************** Import Types ****************************** */
import { DRILLS_QUESTIONS_REQUEST, DRILLS_QUESTIONS_SUCCESS, DRILLS_QUESTIONS_FAILURE } from "../types/drillsQuestion.types";

const initialState = {
  storeItemResponse: "",
  error: "",
  loading: false,
};

const DrillsQuestionsReducer = (state = initialState, action) => {
  
  switch (action.type) {
    case DRILLS_QUESTIONS_REQUEST:
      return {
        ...state,
        storeItemResponse:action.payload||"",
        loading: true,
      };
    case DRILLS_QUESTIONS_SUCCESS:
      return {
        ...state,
        storeItemResponse: action.payload,
        loading: false,
      };
    case DRILLS_QUESTIONS_FAILURE:
      return {
        ...state,
        storeItemResponse:action.payload ,
        error: action.error,
        loading: false,
      };
    default:
      return state;
  }
};

export default DrillsQuestionsReducer;
