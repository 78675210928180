/***************imported packages********************** */
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as yup from "yup";
import { useLocation } from "react-router-dom";
/* ************************** Import Components *************************** **/
import Input from "../../components/formControls/form";
import Button from "../../components/formControls/buttons";
import ButtonLoader from "../../components/loader/buttonLoaders";
/* ************************** Import Images *************************** **/
import LoginImg from "../../assets/images/loginImage.svg";
import logo from "../../assets/images/image/QbaceLogo.svg";
import passwordImg from "../../assets/images/eye.svg";
import passwordShow from "../../assets/images/eye-off.svg";
import lungs from "../../assets/images/Lungs.svg";
import copyright from "../../assets/images/copyright.svg";
/* *********************** Import Redux - Actions ******************** **/
import { loginUser } from "../../redux/actions/login.action";

/* ************************** Import CSS and Styles*************************** **/
import { EyeStyled, SiginWrapper } from "./style";


const SignIn = () => {
  const [passwordShown, setPasswordShown] = useState(false);
  const dispatch = useDispatch();
  const location = useLocation();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const loading = useSelector((state) => state.login.loading);
// **************************formik and yup validation******************************//
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: yup.object({
      email: yup
        .string()
        .required("Email is required")
        .strict()
        .email("Enter valid email"),

      password: yup.string().required("Password is required"),
    }),
    onSubmit: (userInputDate) => {
      dispatch(loginUser(userInputDate)).then((res) => {
        if (res?.message?.type == "password") {
          formik.setFieldError("password", res?.message?.message);
        } else if (res?.message?.type == "email") {
          formik.setFieldError("email", res?.message?.message);
        }
      });
    },
  });

  const onKeyDown = (e) => {
    if (location.pathname === "/") {
      if (e.key === "Enter") {
        e.preventDefault();
        formik.handleSubmit();
      }
      if (e.charCode === 13) {
        formik.handleSubmit();
      }
    }
  };
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [windowWidth]);
  return (
    <SiginWrapper>
      <div className="row" style={{ height: "100vh",width:"100%" }}>
        <div className="col-6" style={{ position: "relative" }}>
          {" "}
          <img src={LoginImg} alt="logo" className="loginImg" />
          <div
            className="Login-description"
            style={{ position: "absolute", top: "20%" }}
          >
            <img
              src={lungs}
              className="lungsImg"
              alt="lungs"
              style={{ width: "80px", height: "80px" }}
            />
            <h1 className="pt-4 login-heading " style={{ fontWeight: "600" }}>
              Hello <br></br>Qbace Admin 👋🏻
            </h1>
            <p className="pt-2 text-start login-desc">
              Your central hub for crafting, managing, and optimizing
              assessments. Seamlessly navigate through features, customize
              settings, and empower educators
            </p>

            <div className="copyRightDiv d-flex justify-content-center align-items-center" >
              <img src={copyright} alt="copyright" className="copyrightImg" />
              <p className="copyright">2024 Qbace All rights reserved</p>
            </div>
          </div>
        </div>
        <div className="col-6 col-md-5 col-lg-5 col-sm-5 col-xl-6">
          <div className="content">
            <img src={logo} alt="logo" className="qbaceLogo" />

            <h2 className="loginHeading" style={{ fontWeight: "600" }}>Login to your account</h2>

            <form onKeyDown={(e) => onKeyDown(e)}>
              <div className="mb-3">
                <label className="label-text mb-2">
                  Email address<span className="mandatory">*</span>
                </label>
                <br />
                <Input
                  width="100%"
                  height="43px"
                  border-radius="6px"
                  border="0.5px solid #E9E9E9"
                  id="login-email"
                  backgroundColor="#FFFFFF"
                  placeholder="Ex: olivia@untitledu@gmail.com"
                  type="text"
                  name="email"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                />
                {formik.errors.email && formik.touched.email ? (
                  <p className="text-danger mb-0 pt-2 font-errors">
                    {formik.errors.email}
                  </p>
                ) : (
                  <p className="mb-0 font-errors"></p>
                )}
              </div>
              <div className="mt-2 mb-4 position-relative">
                <label className="label-text mb-2">
                  Password<span className="mandatory">*</span>
                </label>{" "}
                <br />
                <Input
                  width="100%"
                  height="43px"
                  border-radius="6px"
                  border="0.5px solid #E9E9E9"
                  backgroundColor="#FFFFFF"
                  id="login-password"
                  placeholder="Enter your password"
                  type={passwordShown ? "text" : "password"}
                  name="password"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  onKeyDown={(e) => onKeyDown(e)}
                  value={formik.values.password}
                />
                <EyeStyled>
                  {passwordShown ? (
                    <img
                      src={passwordImg}
                      alt="password"
                      onClick={() => setPasswordShown(false)}
                      className="passwordIcon"
                    />
                  ) : (
                    <img
                      src={passwordShow}
                      alt="password"
                      onClick={() => setPasswordShown(true)}
                      className="passwordIcon"
                    />
                  )}
                </EyeStyled>
                {formik.errors.password && formik.touched.password ? (
                  <p className="text-danger mb-0 pt-2 font-errors">
                    {formik.errors.password}
                  </p>
                ) : (
                  <p className="mb-0 font-errors"></p>
                )}
              </div>
              <div className="mt-4 mb-4">
                <Button
                  text={loading ? <ButtonLoader /> : "Login"}
                  color="white"
                  width="100%"
                  height="43px"
                  id="login-button"
                  border="1px solid white"
                  background="#006AFF"
                  borderRadius="8px"
                  onClick={formik.handleSubmit}
                  type="button"
                  fontWeight="600"
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </SiginWrapper>
  );
};

export default SignIn;
