/** **************************** Import Types ****************************** */
import {
  CONTENTS_REQUEST,
  CONTENTS_SUCCESS,
  CONTENTS_FAILURE,
  CONTENTSID_SUCCESS,
  CONTENTSID_FAILURE,
  CONTENTSID_REQUEST,
} from "../types/contents.types";

const initialState = {
  storeItemResponse: "",
  storegetbyidData: "",
  error: "",
  loading: false,
};

const ContentReducer = (state = initialState, action) => {
  switch (action.type) {
    case CONTENTS_REQUEST:
      return {
        ...state,
        storeItemResponse:"",
        storegetbyidData: "",
        loading: true,
      };
    case CONTENTS_SUCCESS:
      return {
        ...state,
        storeItemResponse: action.payload,
        loading: false,
      };
    case CONTENTS_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case CONTENTSID_REQUEST:
      return {
        ...state,
        // storegetbyidData: "",
        loading: false,
      };
    case CONTENTSID_SUCCESS:
      return {
        ...state,
        storegetbyidData: action.payload,
        loading: false,
      };
    case CONTENTSID_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};

export default ContentReducer;
