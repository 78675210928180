/** **************************** Import Types ****************************** */
import {
    LEADERBOARD_REQUEST,
    LEADERBOARD_SUCCESS,
    LEADERBOARD_FAILURE,
  } from "../types/leaderboard.types";
  
  const initialState = {
    storeItemResponse: "",
    error: "",
    loading: false,
  };
  
  const LeaderboardReducer = (state = initialState, action) => {
    switch (action.type) {
      case LEADERBOARD_REQUEST:
        return {
          ...state,
  
          loading: true,
        };
      case LEADERBOARD_SUCCESS:
        return {
          ...state,
          storeItemResponse: action.payload,
          loading: false,
        };
      case LEADERBOARD_FAILURE:
        return {
          ...state,
          error: action.payload,
          loading: false,
        };
      default:
        return state;
    }
  };
  
  export default LeaderboardReducer;
  