/** **************************** Import Types ****************************** */
import {
    HTMLFORM_FAILURE,
    HTMLFORM_SUCCESS,
    HTMLFORM_REQUEST,
    HTML_FAILURE,
    HTML_SUCCESS,
    HTML_REQUEST
  } from "../types/htmlConversion.types";
  
  const initialState = {
    storeItemResponse: "",
    error: "",
    loading: false,
  };
  
  const HtmlReducer = (state = initialState, action) => {
    switch (action.type) {
      case HTML_REQUEST:
        return {
          ...state,
          storeItemResponse: "",
          loading: true,
        };
      case HTML_SUCCESS:
        return {
          ...state,
          storeItemResponse: action.payload,
          loading: false,
        };
      case HTML_FAILURE:
        return {
          ...state,
          error: action.payload,
          loading: false,
        };
        case HTMLFORM_REQUEST:
        return {
          ...state,
          storeItemResponse: "",
          loading: true,
        };
      case HTMLFORM_SUCCESS:
        return {
          ...state,
          storeItemResponse: action.payload,
          loading: false,
        };
      case HTMLFORM_FAILURE:
        return {
          ...state,
          error: action.payload,
          loading: false,
        };
      default:
        return state;
    }
  };
  
  export default HtmlReducer;
  