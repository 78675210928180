/** **************************** Import Types ****************************** */
import {MEGABATTLE_QUESTION_REQUEST,MEGABATTLE_QUESTION_SUCCESS,MEGABATTLE_QUESTION_FAILURE } from "../types/megabattleQuestions.types";

const initialState = {
  storeItemResponse: "",
  error: "",
  loading: false,
};

const MegaBattleQuestionReducer = (state = initialState, action) => {
  
  switch (action.type) {
    case MEGABATTLE_QUESTION_REQUEST:
      return {
        ...state,
        // storeItemResponse: "",
        loading: true,
      };
    case MEGABATTLE_QUESTION_SUCCESS:
      return {
        ...state,
        storeItemResponse: action.payload,
        loading: false,
      };
    case MEGABATTLE_QUESTION_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};

export default MegaBattleQuestionReducer;
