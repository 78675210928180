/** ****************************** Import libs *********************************** */
import { postDataApi, postRequesByIdApi, uploadFile } from "./action";
import { API_ENDPOINT } from "./urls";

export const postLoginRequestData = (params) =>
  postDataApi(API_ENDPOINT.login, params);

export const postLogout = (params) =>
  postRequesByIdApi(API_ENDPOINT.logout, params);
/*********************topics********************* */
export const postTopics = (params) => postDataApi(API_ENDPOINT.topics, params);

export const UploadFiles = (params) =>
  uploadFile(API_ENDPOINT.uploadTopic_image, params);
/*********************sub-topics********************* */
export const postSubtopics = (params) =>
  postDataApi(API_ENDPOINT.sub_topic, params);

/************************************bootcamp*************************** */

export const postBootcampModules = (params) =>
  postDataApi(API_ENDPOINT.bootcamp_postmodule, params);

export const postBootcampQuestions = (params) =>
  postDataApi(API_ENDPOINT.bootcamp_postquestion, params);

export const UploadQuestions = (params, id) =>
  uploadFile(API_ENDPOINT.bootcamp_uploadquestion, params, id);
export const CheckFileValidation = (params, id) =>
  uploadFile(API_ENDPOINT.bootcamp_checkFile, params, id);

/************************************drills*************************** */

export const postDrillsModules = (params) =>
  postDataApi(API_ENDPOINT.drills_postmodule, params);

export const postDrillsQuestions = (params) =>
  postDataApi(API_ENDPOINT.drills_postquestion, params);

export const UploadDrillsQuestions = (params, id) =>
  uploadFile(API_ENDPOINT.drills_uploadquestion, params, id);
export const drillsCheckFileValidation = (params, id) =>
  uploadFile(API_ENDPOINT.drills_checkFile, params, id);

/********************************Admin user******************* */

export const postAdminUsers = (params) =>
  postDataApi(API_ENDPOINT.admin, params);
export const PostProfileImg = (params) =>
  uploadFile(API_ENDPOINT.admin_profile, params);

/********************************notes************************** */
export const postNotes = (params) => postDataApi(API_ENDPOINT.notes, params);

/************************mega battle ************************* */
/***********************tests**************************** */
export const postMegaBattleTests = (params) =>
  postDataApi(API_ENDPOINT.megabattle, params);

export const postmegabattleQuestions = (params) =>
  postDataApi(API_ENDPOINT.megabattle_postquestion, params);

export const CheckmegabattleFileValidation = (params, id) =>
  uploadFile(API_ENDPOINT.megabattle_checkFile, params, id);

export const UploadmegabattleQuestions = (params, id) =>
  uploadFile(API_ENDPOINT.megabattle_uploadquestion, params, id);

// ***********************image bank*************************//
export const postImageBank = (params) =>
  postDataApi(API_ENDPOINT.imageBank, params);

export const PostImageBankImg = (params) =>
  uploadFile(`${API_ENDPOINT.imageBank}/upload_image`, params);

// **********************News feed************************//

export const postNewsfeed = (params) =>
  postDataApi(API_ENDPOINT.newsfeed, params);

export const UploadNewsfeedFiles = (params) =>
  uploadFile("newsfeed/upload_image", params);

// *****************Html*********************************//

export const postHTML = (params) =>
  postDataApi(API_ENDPOINT.html_convertor, params);

// ********************badges*********************************//
export const postBadges = (params) => postDataApi("rewards/addrewards", params);

export const UploadBadgesImage = (params) =>
  uploadFile(API_ENDPOINT.badges_images, params);

// *********************subscription********************************//
export const postsubscription = (params) =>
  postDataApi(API_ENDPOINT.subscription, params);

export const Override = (params, id) => {
  return postDataApi(API_ENDPOINT.override, params);
};
// ********************Faq*******************************//
export const postFaq = (params) => postDataApi(API_ENDPOINT.faq, params);

// *****************notification**************************//

export const postNotification = (params) =>
  postDataApi(API_ENDPOINT.notification, params);
export const resendNotificationApi = (params) => {
  return postDataApi(API_ENDPOINT.notification_resend, params);
};

// *****************Users**************************//
export const addSubscription = (params) =>
  postDataApi(API_ENDPOINT.add_subscription, params);
