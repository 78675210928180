/** **************************** Import Types ****************************** */
import {MEGABATTLE_TESTS_REQUEST,MEGABATTLE_TESTS_SUCCESS,MEGABATTLE_TESTS_FAILURE } from "../types/megaBattleTests.types";

const initialState = {
  storeItemResponse: "",
  error: "",
  loading: false,
};

const MegaBattleTestReducer = (state = initialState, action) => {
  
  switch (action.type) {
    case MEGABATTLE_TESTS_REQUEST:
      return {
        ...state,
        // storeItemResponse: "",
        loading: true,
      };
    case MEGABATTLE_TESTS_SUCCESS:
      return {
        ...state,
        storeItemResponse: action.payload,
        loading: false,
      };
    case MEGABATTLE_TESTS_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};

export default MegaBattleTestReducer;
