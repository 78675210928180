/** **************************** Import Packages ****************************** */
import { toast } from "react-toastify";

/** **************************** Import types ****************************** */
import {
  LOGIN_USERS_REQUEST,
  LOGIN_USERS_SUCCESS,
  LOGIN_USERS_FAILURE,
} from "../types/login.types";

/** **************************** Import API Methods ****************************** */
import { postLoginRequestData } from "../../api/create";

/** **************************** Import Utils ****************************** */
import { normalize } from "../../utils/camelizeAndNormalize";

/** **************************** Import CSS ****************************** */
import "react-toastify/dist/ReactToastify.css";

/************************images and icons****************** */
import successIcon from "../../assets/images/successToaster.svg";
import errorIcon from "../../assets/images/toasterError.svg";
import infoIcon from "../../assets/images/toasterInfo.svg";


export const loginUsersRequest = () => ({
  type: LOGIN_USERS_REQUEST,
});
export const loginUsersSuccess = (users) => ({
  type: LOGIN_USERS_SUCCESS,
  payload: users,
});
export const loginUsersFailure = (error) => ({
  type: LOGIN_USERS_FAILURE,
  payload: error,
});

export const loginUser = (data) =>
  async function (dispatch) {
    dispatch(loginUsersRequest());
    return postLoginRequestData(data).then((res) => {
      if (res?.success) {
        const loggedUser = res.data;
        const accessToken = res.data?.access_token;
        localStorage.setItem("accessToken", accessToken);
        localStorage.setItem("loggedUser", JSON.stringify(loggedUser));
        dispatch(loginUsersSuccess(res));
        const SuccessToast = () => (
          <div className="mt-3">
            <span className="mainText pt-2">Logged in successfully</span>
         
          </div>
        );
    
        toast.success(<SuccessToast/>,{
          icon: <img src={successIcon} alt="success" />,
        });
        // toast.success("Logged in successfully");
        setTimeout(() => {
          window.location.href = "/home/dashboard";
        }, 3000);
        return res;
      } else {
        dispatch(loginUsersFailure(res?.detail));

        return res?.detail;
      }
    });
  };
