/** **************************** Import Types ****************************** */
import {
  BOOTCAMP_MODULES_REQUEST,
  BOOTCAMP_MODULES_SUCCESS,
  BOOTCAMP_MODULES_FAILURE,
} from "../types/bootcampModules.types";

const initialState = {
  storeItemResponse: "",
  error: "",
  loading: false,
};

const BootcampModuleReducer = (state = initialState, action) => {
  switch (action.type) {
    case BOOTCAMP_MODULES_REQUEST:
      return {
        ...state,
        storeItemResponse: "",
        loading: true,
      };
    case BOOTCAMP_MODULES_SUCCESS:
      return {
        ...state,
        storeItemResponse: action.payload,
        loading: false,
      };
    case BOOTCAMP_MODULES_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};

export default BootcampModuleReducer;
