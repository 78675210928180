/** **************************** Import Types ****************************** */
import {
    BADGES_REQUEST,
    BADGES_SUCCESS,
    BADGES_FAILURE,
  } from "../types/badges.types";
  
  const initialState = {
    storeItemResponse: "",
    error: "",
    loading: false,
  };
  
  const badgesReducer = (state = initialState, action) => {
    switch (action.type) {
      case BADGES_REQUEST:
        return {
          ...state,
          storeItemResponse: "",
          loading: true,
        };
      case BADGES_SUCCESS:
        return {
          ...state,
          storeItemResponse: action.payload,
          loading: false,
        };
      case BADGES_FAILURE:
        return {
          ...state,
          error: action.payload,
          loading: false,
        };
      default:
        return state;
    }
  };
  
  export default badgesReducer;
  