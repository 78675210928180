/** **************************** Import Types ****************************** */
import {
    USERPERFORMANCE_REQUEST,
    USERPERFORMANCE_SUCCESS,
    USERPERFORMANCE_FAILURE,
  } from "../types/userPerformance.types";
  
  const initialState = {
    storeItemResponse: "",
    error: "",
    loading: false,
  };
  
  const UserPerformanceReducer = (state = initialState, action) => {
    switch (action.type) {
      case USERPERFORMANCE_REQUEST:
        return {
          ...state,
          storeItemResponse: "",
          loading: true,
        };
      case USERPERFORMANCE_SUCCESS:
        return {
          ...state,
          storeItemResponse: action.payload,
          loading: false,
        };
      case USERPERFORMANCE_FAILURE:
        return {
          ...state,
          error: action.payload,
          loading: false,
        };
      default:
        return state;
    }
  };
  
  export default UserPerformanceReducer;
  