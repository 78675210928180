/** **************************** Import Types ****************************** */
import {
  TOPICS_REQUEST,
  TOPICS_SUCCESS,
  TOPICS_FAILURE,
} from "../types/topics.types";

const initialState = {
  storeItemResponse: "",
  error: "",
  loading: false,
};

const TopicsReducer = (state = initialState, action) => {
  switch (action.type) {
    case TOPICS_REQUEST:
      return {
        ...state,
        storeItemResponse: "",
        loading: true,
      };
    case TOPICS_SUCCESS:
      return {
        ...state,
        storeItemResponse: action.payload,
        loading: false,
      };
    case TOPICS_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};

export default TopicsReducer;
