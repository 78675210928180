/** **************************** Import Types ****************************** */
import {
    IMAGEBANK_REQUEST,
    IMAGEBANK_SUCCESS,
    IMAGEBANK_FAILURE,
  } from "../types/imageBank.types";
  
  const initialState = {
    storeItemResponse: "",
    error: "",
    loading: false,
  };
  
  const ImageReducer = (state = initialState, action) => {
    switch (action.type) {
      case IMAGEBANK_REQUEST:
        return {
          ...state,
          loading: true,
        };
      case IMAGEBANK_SUCCESS:
        return {
          ...state,
          storeItemResponse: action.payload,
          loading: false,
        };
      case IMAGEBANK_FAILURE:
        return {
          ...state,
          error: action.payload,
          loading: false,
        };
      default:
        return state;
    }
  };
  
  export default ImageReducer;
  