/* ************************** Import Packages *************************** **/
import styled from "styled-components";

export const EyeStyled = styled.div`
  position: absolute;
  top: 2.4rem;
  right: 1rem;
  cursor: pointer;
  img {
    height: 20px;
  }
  .passwordIcon {
    width: 20px;
    height: 20px;
  }
`;

export const SiginWrapper = styled.div`
  overflow: none;
  .Login-description {
    width: 74%;
    margin-left: 120px;
  }
  img {
    height: 100vh;
  }
  .copyright {
    color: #ffffffb2;

    font-size: 16px;
    font-weight: 500;
  }
  .copyRightDiv {
    width: 90%;
    margin-top: 150px;
  }
  .copyrightImg {
    width: 20px;
    height: 20px;
    margin-right: 12px;
  }
  .content {
    width: 60%;
    height: 50vh;
    margin-top: 25vh;
    margin-left: 5%;
  }
  .qbaceLogo {
    width: 168px;
    height: 38px;
    margin-bottom: 20px;
  }
  .label-text {
    color: #151617;
    font-size: 14px;
  }
  .login-heading {
    font-size: 54px;
    font-weight: 700;
    line-height: 74px;
    text-align: left;

    color: #ffffff;
  }
  .login-desc {
    width: 90%;
    font-size: 18px;
    font-weight: 500;
    line-height: 28px;
    text-align: left;
    color: #ffffffcc;
  }
  .loginImg {
    width: 100%;
    object-fit: cover;
  }
  @media (min-width: 1024px) and (max-width: 1300px) {
    .content {
      width: 80%;
    }
    .copyRightDiv {
      width: 90%;
      margin-top: 60px;
    }
  }

  @media (min-width: 1024px) and (max-width: 1200px) {
    .Login-description {
      width: 90%;
      margin-left: 50px;
      top: 10%;
    }
  }
`;
