import { BREAD_CRUM } from "../types/navBar.types";

const initialState = {
  breadCrum: [
    {
      title: "Dashboard",
      path: "/home/dashboard",
    },
  ],
};
export const breadCrumReducer = (state = initialState, action) => {
  switch (action.type) {
    case BREAD_CRUM:
      return {
        ...state,
        breadCrum: action.payload,
      };
    default:
      return state;
  }
};
