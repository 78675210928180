/** **************************** Import Types ****************************** */
import {
    FEEDBACK_REQUEST,
    FEEDBACK_SUCCESS,
    FEEDBACK_FAILURE,
    FEEDBACKID_SUCCESS,
    FEEDBACKID_REQUEST,
    FEEDBACKID_FAILURE
  } from "../types/feedback.types";
  
  const initialState = {
    storeItemResponse: "",
    storegetbyidData:"",
    error: "",
    loading: false,
  };
  
  const FeedBackReducer = (state = initialState, action) => {
    switch (action.type) {
      case FEEDBACK_REQUEST:
        return {
          ...state,
           storeItemResponse: "", 
          loading: true,
        };
      case FEEDBACK_SUCCESS:
        return {
          ...state,
          storeItemResponse: action.payload,
          loading: false,
        };
      case FEEDBACK_FAILURE:
        return {
          ...state,
          error: action.payload,
          loading: false,
        };
        // case FEEDBACKID_REQUEST:
        //   return {
        //     ...state,
        //     storegetbyidData: "",
        //     loading: false,
        //   };
        //   case FEEDBACKID_FAILURE:
        //   return {
        //     ...state,
        //     error: action.payload,
        //     loading: false,
        //   };
        case FEEDBACKID_SUCCESS:
          return {
            ...state,
            storegetbyidData: action.payload,
            loading: false,
          };
      default:
        return state;
    }
  };
  
  export default FeedBackReducer;
  