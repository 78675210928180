/** **************************** Import Packages ****************************** */
import React, { useEffect } from "react";
import { ThemeProvider } from "styled-components";
import { BrowserRouter as Router, Switch, Route, useLocation } from "react-router-dom";
import { ToastContainer, Zoom } from "react-toastify";

import "./App.css";
/** **************************** Import Constants ****************************** */
import { DarkTheme } from "./constants/themes";

/** **************************** Import Pages ****************************** */
import Login from "./pages/signInModule";

/** **************************** Import CSS ****************************** */
// import "./App.css";

const App = () => {
  const Layout = React.lazy(() => import("./container/layoutContainer"));

 const LocationHandler = () => {
  const location = useLocation();

  useEffect(() => {
    const existingPath = "/home/userdetails";
   
    if (!existingPath.includes(location.pathname)) {
      localStorage.removeItem("selectedTab");
    }

   
    
  }, [location]);

  return null; // This component doesn't render anything
};


  return (
    <ThemeProvider theme={DarkTheme}>
      <Router>
      <LocationHandler />
        <React.Suspense
          fallback={
            <div
              className="d-grid justify-content-center align-items-center"
              style={{ height: "100vh" }}
            >
              {/* <img src={SpinnerImg} width="100%" /> */}
            </div>
          }
        >
          <Switch>
            <Route
              exact
              path="/"
              render={() =>
                localStorage.getItem("loggedUser") ? (
                  (window.location.href = "/home/dashboard")
                ) : (
                  <Login />
                )
              }
            />

            <Route
              path="/home"
              name="Home"
              render={(props) =>
                localStorage.getItem("loggedUser") ? (
                  <Layout {...props} />
                ) : (
                  (window.location.href = "/")
                )
              }
            />

            <Route path="*" exact={true} />
          </Switch>
        </React.Suspense>
      </Router>
      <div className="toasterr">
        <ToastContainer
          limit={1}
          autoClose={2000}
          transition={Zoom}
          pauseOnFocusLoss={false}
          newestOnTop
          hideProgressBar={true}
        />
      </div>
    </ThemeProvider>
  );
};

export default App;
