/** **************************** Import Libs ****************************** */
import { combineReducers } from "redux";

/** **************************** Import Reducers ****************************** */
import loginReducer from "./reducers/login.reducer";
import { breadCrumReducer } from "./reducers/navBar.reducer";
import { sideBarReducer } from "./reducers/sideBar.reducer";
import { NavBarReducer } from "./reducers/headNav.reducer";
import { profileDropDownReducer } from "./reducers/profileDropDown.reducer";
import  TopicsReducer from "./reducers/topics.reducer";
import  SubtopicsReducer from "./reducers/subtopics.reducer";
import  BootcampSubtopicsReducer from "./reducers/bootcampSubtopics.reducer";
import  BootcampModulesReducer from "./reducers/bootcampModules.reducer";
import  BootcampQuestionsReducer from "./reducers/bootcampQuestions.reducer";
import DrillsSubtopicsReducer from "./reducers/drillsSubtopics.reducer";
import DrillsModulesReducer from "./reducers/drillsModules.reducer";
import DrillsQuestionsReducer from "./reducers/drillsQuestions.reducer";
import AdminUserReducer from "./reducers/adminUser.reducer";
import NotesReducer from "./reducers/notes.reducer"
import BreadcrumbReducer from "./reducers/breadcrumb.reducer";
import MegaBattleTestReducer from "./reducers/megaBattletests.reducer"
import MegaBattleQuestionReducer from "./reducers/megabattleQuestion.reducer";
import LeaderboardReducer from "./reducers/leaderboard.reducer";
import ImageReducer from "./reducers/imageBank.reducer";
import OpenReportsReducer from "./reducers/openReports.reducer";
import ClosedReportsReducer from "./reducers/closedReports.reducer";
import NotesAddReducer from "./reducers/notes.Addreducer"
import FeedBackReducer from "./reducers/feedback.reducer";
import NewsfeedReducer from "./reducers/newsfeed.reducer";
import MobileuserReducer from "./reducers/mobileUser.reducer";
import HtmlReducer from "./reducers/htmlConversion.reducer";
import BadgesReducer from "./reducers/badges.reducer";
import ContentsReducer from "./reducers/contents.reducer";
import UserPerformanceReducer from "./reducers/userPerformance.reducer";
import InquiryReducer from "./reducers/inquiry.reducer";
import SubscriptionReducer from "./reducers/subscription.reducer";
import FaqReducer from "./reducers/faq.reducer";
import NotificationReducer from "./reducers/notification.reducer";
const rootReducer = combineReducers({
  login: loginReducer,
  navBar: breadCrumReducer,
  SideBar: sideBarReducer,
  NavbarReduce: NavBarReducer,
  profileDropdown: profileDropDownReducer,
  TopicsReducer:TopicsReducer,
  SubtopicsReducer:SubtopicsReducer,
  BootcampSubtopicsReducer:BootcampSubtopicsReducer,
  BootcampModulesReducer:BootcampModulesReducer,
  BootcampQuestionsReducer:BootcampQuestionsReducer,
  AdminUserReducer:AdminUserReducer,
  NotesReducer:NotesReducer,
  NotesAddReducer:NotesAddReducer,
  DrillsQuestionsReducer:DrillsQuestionsReducer,
  DrillsSubtopicsReducer:DrillsSubtopicsReducer,
  DrillsModulesReducer:DrillsModulesReducer,
  BreadcrumbReducer:BreadcrumbReducer,
  MegaBattleTestReducer:MegaBattleTestReducer,
  MegaBattleQuestionReducer:MegaBattleQuestionReducer,
  LeaderboardReducer:LeaderboardReducer,
  ImageReducer:ImageReducer,
  OpenReportsReducer:OpenReportsReducer,
  ClosedReportsReducer:ClosedReportsReducer,
  FeedBackReducer:FeedBackReducer,
  NewsfeedReducer:NewsfeedReducer,
  MobileuserReducer:MobileuserReducer,
  HtmlReducer:HtmlReducer,
  BadgesReducer:BadgesReducer,
  ContentsReducer:ContentsReducer,
  UserPerformanceReducer:UserPerformanceReducer,
  InquiryReducer:InquiryReducer,
  SubscriptionReducer:SubscriptionReducer,
  FaqReducer:FaqReducer,
  NotificationReducer:NotificationReducer
});

export default rootReducer;
