/** **************************** Import Types ****************************** */
import {
    CLOSEDREPORT_REQUEST,
    CLOSEDREPORT_SUCCESS,
    CLOSEDREPORT_FAILURE,
  } from "../types/closedReports.types";
  
  const initialState = {
    storeItemResponse: "",
    error: "",
    loading: false,
  };
  
  const ClosedReportsReducer = (state = initialState, action) => {
    switch (action.type) {
      case CLOSEDREPORT_REQUEST:
        return {
          ...state,
  
          loading: true,
        };
      case CLOSEDREPORT_SUCCESS:
        return {
          ...state,
          storeItemResponse: action.payload,
          loading: false,
        };
      case CLOSEDREPORT_FAILURE:
        return {
          ...state,
          error: action.payload,
          loading: false,
        };
      default:
        return state;
    }
  };
  
  export default ClosedReportsReducer;
  